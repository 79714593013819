import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PieChart } from "@mui/x-charts/PieChart";

import { ReactComponent as Logo } from "../assets/logo.svg";
import styles from "../assets/styles/diskquota.module.css";
import api from "../middleware/api";
import MyContext from "../context/MyContext";

const DiskQuota = () => {
  const [used, setUsed] = useState(0);
  const [free, setFree] = useState(0);

  const [isLoading, setIsLoading] = useState(null);

  const navigate = useNavigate();

  const { accessLevel } = useContext(MyContext);

  useEffect(() => {
    if (accessLevel === "EhealthUsers") {
      navigate("/home/");
    }
  }, [accessLevel]);

  const handleCheckClick = async () => {
    try {
      setIsLoading(true);

      // Update za 15min token
      const response2 = await api.get("/api/DoctorAuth/user");
      const ime = response2.data.name;

      // Proveri quota za korisnik
      const response = await api.get(`/api/Quota/check?username=${ime}`);

      // Zemi iskoristena i maximum limit
      const usedValue = response.data.split(" ").filter((x) => x !== "")[25];
      const limitValue = response.data.split(" ").filter((x) => x !== "")[26];

      const helper1 = `Disk space used: ${usedValue} \nDisk limit: ${limitValue}`;

      const regex = /Disk space used: (\d+)([KMG])/;
      const logOutput = helper1;

      // Function to convert the matched unit to kilobytes (K)
      const convertToKB = (value, unit) => {
        switch (unit) {
          case "K":
            return parseInt(value, 10);
          case "M":
            return parseInt(value, 10) * 1024;
          case "G":
            return parseInt(value, 10) * 1024 * 1024;
          default:
            return 0;
        }
      };

      const usedSpaceMatch = logOutput.match(regex);
      if (usedSpaceMatch && usedSpaceMatch.length === 3) {
        const usedSpaceInKB = convertToKB(usedSpaceMatch[1], usedSpaceMatch[2]);

        // Repeat the process for the disk limit
        const limitMatch = logOutput.match(/Disk limit: (\d+)([KMG])/);
        if (limitMatch && limitMatch.length === 3) {
          const totalLimitInKB = convertToKB(limitMatch[1], limitMatch[2]);

          // Calculate the progress ratio as a percentage
          const progressPercentage = (usedSpaceInKB / totalLimitInKB) * 100;

          // Update the state with the progress ratio
          const roundedNumber = Number(progressPercentage.toFixed(2));
          const roundedNumber2 = Number((100 - progressPercentage).toFixed(2));
          setUsed(roundedNumber);
          setFree(roundedNumber2);
        }
      }
      // Assuming the response contains 'used' and 'free' percentages
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleCheckClick();
  }, []); // Empty dependency array ensures this runs only once

  if (accessLevel === "null") {
    return null;
  }

  return (
    <main className={styles.main}>
      <div className={styles.wrapper}>
        <Logo alt="" className={styles.logo} />
        <button
          disabled={isLoading}
          className={
            isLoading ? `${styles.unavailableButton}` : `${styles.button}`
          }
          onClick={handleCheckClick}
        >
          Check
        </button>
        <div className={styles.asd}>
          <PieChart
            series={[
              {
                data: [
                  {
                    id: 0,
                    value: used,
                    label: `Used: ${used}%`,
                    color: "rgb(226, 0, 116)",
                  },
                  {
                    id: 1,
                    value: free,
                    label: `Free: ${free}%`,
                    color: "rgb(180, 180, 180)",
                  },
                ],
              },
            ]}
            width={420}
            height={210}
          />
        </div>
        <Link to="/home/" className={styles.back}>
          Go Back
        </Link>
      </div>
    </main>
  );
};

export default DiskQuota;
