import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from "../assets/logo.svg";
import styles from "../assets/styles/formcrud.module.css";
import api from "../middleware/api";

const ActivateAccountScreen = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [shouldPasswordMessage, shouldSetPasswordMessage] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState("");

  const [isLoading, setIsLoading] = useState(null);

  const [checkPassword, setCheckPassword] = useState("null");
  const [gotovo, setGotovo] = useState(null);
  const [greska, setGreska] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState("null");

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "newPassword") setNewPassword(value);
    if (name === "confirmNewPassword") setConfirmNewPassword(value);
  };

  useEffect(() => {
    // Proveri dali e go ima smeneto passwordot
    const fetchData = async () => {
      try {
        // Updejtiraj JWT kolace
        const response = await api.get(`/api/DoctorAuth/user`, {
          withCredentials: true,
        });

        // Proveri go statusot vo MySQL za userot
        const accountStatus = response.data.accountStatus.accountStatus;

        setIsAuthenticated("moze");
        // Ako moze neka mu renderira html za da go aktivira akauntot, ako e Activated te
        // prenasocuva na /home/ zaso vekje e aktiven
        if (accountStatus === "Activated") {
          setIsAuthenticated("true");
        }
      } catch (error) {
        setIsAuthenticated("false");
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []); // Empty dependency array ensures this effect runs only once

  const handleCreate = async () => {
    setGotovo(false);
    setGreska(false);
    setIsLoading(true);

    if (newPassword !== confirmNewPassword) {
      setCheckPassword("ok");
      setGreska(true);
      setIsLoading(false);
      shouldSetPasswordMessage(true);
      setPasswordMessage("Passwords do not match.");
      return;
    }

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?!.*[^a-zA-Z0-9])(.{8,})$/;
    if (!passwordRegex.test(newPassword)) {
      setCheckPassword("ok");
      setGreska(true);
      setIsLoading(false);
      shouldSetPasswordMessage(true);
      setPasswordMessage(
        "Password length have to be above 7 characters,\nAnd contain at least one lowercase letter (a-z),\nOne uppercase letter (A-Z),\nOne digit (0-9).\nSpecial characters are not allowed."
      );
      return;
    } else {
      shouldSetPasswordMessage(false);
      try {
        const response1 = await api.get(`/api/DoctorAuth/user`);
        if (response1.status == 200) {
          await api.post("api/User/updateUser", {
            // username: response1.data.name,
            password: newPassword,
          });

          await api.post("/api/AD/modifyUserPasswordWithJWT", {
            Dn: "emptyCuzNotNeeded",
            NewPassword: newPassword,
          });

          await api.put("/api/DoctorAuth/updatePasswordJWT", {
            Password: newPassword,
          });

          await api.post("/api/AD/modifyUserAccountControlWithJWT", {});

          await api.put("/api/DoctorAuth/updateDoctorAccountStatusWithJWT", {});

          await api.post("/api/Email/sendEmail", {
            ClientEmail: "nebitno",
            Task: "ChangedPassword",
            Username: "nebitno",
          });

          setGotovo(true);
          setCheckPassword(false);

          await api.post(
            `/api/DoctorAuth/logout`,
            {} // Pass an empty object as the data payload
          );
          localStorage.removeItem("jwt");
          navigate("/home/login");
        }
      } catch (e) {
        setGreska(true);
        setCheckPassword("ok");
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Render nothing until authentication check is complete
  if (isAuthenticated === "null") {
    return null;
  }

  if (isAuthenticated === "true" || isAuthenticated === "false") {
    navigate("/home/");
  }

  if (isAuthenticated === "moze") {
    return (
      <main className={styles.main}>
        <div className={styles.wrapper}>
          <Logo alt="" className={styles.logo} />
          <div className={styles.formWindow5}>
            <div className={styles.divForm5}>
              Set New Password & Activate Account
            </div>
            <form>
              <label>
                <p>New Password:</p>
                <input
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                <p>Confirm New Password:</p>
                <input
                  type="password"
                  name="confirmNewPassword"
                  value={confirmNewPassword}
                  onChange={handleInputChange}
                />
              </label>
            </form>
            <div className={styles.kek321}>
              <button
                disabled={isLoading}
                className={
                  isLoading ? `${styles.unavailableButton2}` : `${styles.lol2}`
                }
                onClick={handleCreate}
              >
                Submit
              </button>
              <div
                className={
                  isLoading === true || isLoading === false
                    ? styles.wrapper2
                    : ""
                }
              >
                {isLoading && <span className={styles.loader}></span>}
                {gotovo && <span className={styles.done12}>Done</span>}
                {greska && <span className={styles.error1232}>Error</span>}
              </div>
            </div>
            {checkPassword == "ok" && (
              <div className={styles.error12}>
                {shouldPasswordMessage
                  ? passwordMessage.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))
                  : ""}
              </div>
            )}
            {!checkPassword && (
              <div className={styles.error12}>
                Password Changed Successfully
              </div>
            )}
          </div>
        </div>
      </main>
    );
  }
};

export default ActivateAccountScreen;
