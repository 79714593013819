import axios from "axios";

// Configure Axios instance with secret key
// Middleware za api, povikaj go so samo api.REQUEST, se isprakja kolaceto JWT i tajniot kluc
const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`, // API base URL
  headers: {
    SECRET_KEY: process.env.REACT_APP_SECRET_KEY,
  },
  withCredentials: true,
});

export default api;
