import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../assets/logo.svg";
import styles from "../assets/styles/formcrud.module.css";
import api from "../middleware/api";

const ChangePasswordScreen = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [shouldPasswordMessage, shouldSetPasswordMessage] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [shouldOldPasswordMessage, shouldOldSetPasswordMessage] =
    useState(null);
  const [oldPasswordMessage, setOldPasswordMessage] = useState(
    "Wrong Old Password."
  );

  const [isLoading, setIsLoading] = useState(null);

  const [checkPassword, setCheckPassword] = useState("null");
  const [gotovo, setGotovo] = useState(null);
  const [greska, setGreska] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "oldPassword") setOldPassword(value);
    if (name === "newPassword") setNewPassword(value);
    if (name === "confirmNewPassword") setConfirmNewPassword(value);
  };

  const handleCreate = async () => {
    setGotovo(false);
    setGreska(false);
    setIsLoading(true);

    if (newPassword !== confirmNewPassword) {
      setCheckPassword("ok");
      setGreska(true);
      setIsLoading(false);
      shouldSetPasswordMessage(true);
      setPasswordMessage("Passwords do not match.");
      return;
    }

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?!.*[^a-zA-Z0-9])(.{8,})$/;

    // Proveri dali ispolnuva requirements
    if (!passwordRegex.test(newPassword)) {
      setCheckPassword("ok");
      setGreska(true);
      setIsLoading(false);
      shouldSetPasswordMessage(true);
      setPasswordMessage(
        "Password length have to be above 7 characters,\nAnd contain at least one lowercase letter (a-z),\nOne uppercase letter (A-Z),\nOne digit (0-9).\nSpecial characters are not allowed."
      );
      shouldOldSetPasswordMessage(false);
      return;
    } else {
      // Ako ispolnuva
      shouldSetPasswordMessage(false);
      try {
        // Update token 15min
        const response1 = await api.get(`/api/DoctorAuth/user`);
        if (response1.status == 200) {
          // Proveri password vo MySQL
          const response = await api.post(`/api/DoctorAuth/checkPassword`, {
            // Email: response1.data.name,
            OldPassword: oldPassword,
            NewPassword: newPassword,
          });
          if (response.status == 200) {
            // Update password za Linux, Samba
            await api.post("api/User/updateUser", {
              // username: response1.data.name,
              password: newPassword,
            });

            // Za ovoj korisnik Update vo Active Directory
            await api.post("/api/AD/modifyUserPasswordWithJWT", {
              Dn: "emptyCuzNotNeeded",
              NewPassword: newPassword,
            });

            // Isprati Mail deka e smenet passwordot
            const response40 = await api.post("/api/Email/sendEmail", {
              ClientEmail: "nebitno",
              Task: "ChangedPassword",
              Username: "nebitno",
            });

            if (response40.status == 200) {
              setGotovo(true);
              setCheckPassword(false);
            }
          }
        }
      } catch (e) {
        setGreska(true);
        shouldOldSetPasswordMessage(true);
        setCheckPassword("ok");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <main className={styles.main}>
      <div className={styles.wrapper}>
        <Logo alt="" className={styles.logo} />
        <div className={styles.formWindow}>
          <form>
            <label>
              <p>Old Password:</p>
              <input
                type="password"
                name="oldPassword"
                value={oldPassword}
                onChange={handleInputChange}
              />
            </label>
            <label>
              <p>New Password:</p>
              <input
                type="password"
                name="newPassword"
                value={newPassword}
                onChange={handleInputChange}
              />
            </label>
            <label>
              <p>Confirm New Password:</p>
              <input
                type="password"
                name="confirmNewPassword"
                value={confirmNewPassword}
                onChange={handleInputChange}
              />
            </label>
          </form>
          <div className={styles.kek123}>
            <button
              disabled={isLoading}
              className={
                isLoading ? `${styles.unavailableButton44}` : `${styles.lol44}`
              }
              onClick={handleCreate}
            >
              Change Password
            </button>
            <div
              className={
                isLoading === true || isLoading === false ? styles.wrapper1 : ""
              }
            >
              {isLoading && <span className={styles.loader}></span>}
              {gotovo && <span className={styles.done1}>Done</span>}
              {greska && <span className={styles.error123}>Error</span>}
            </div>
          </div>
          {checkPassword == "ok" && (
            <div className={styles.error12}>
              {shouldOldPasswordMessage && oldPasswordMessage}
              {shouldPasswordMessage
                ? passwordMessage.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))
                : ""}
            </div>
          )}
          {!checkPassword && (
            <div className={styles.error12}>Password Changed Successfully</div>
          )}
        </div>
        <Link to="/home/" className={styles.back}>
          Go Back
        </Link>
      </div>
    </main>
  );
};

export default ChangePasswordScreen;
