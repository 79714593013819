import React, { createContext, useState } from "react";

const MyContext = createContext();

// Globalna promenliva
export const MyProvider = ({ children }) => {
  const [accessLevel, setAccessLevel] = useState("null");

  return (
    <MyContext.Provider
      value={{
        accessLevel,
        setAccessLevel,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export default MyContext;
