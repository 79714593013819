import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";

import styles from "../assets/styles/homeScreen.module.css";
import api from "../middleware/api";
import MyContext from "../context/MyContext";
import axios from "axios";

const HomeScreen = () => {
  const navigate = useNavigate();

  const { accessLevel } = useContext(MyContext);

  // Logout handler function
  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      // Brise kolace od Filebrowser, MySQL
      await api.post(
        `/api/DoctorAuth/logout`,
        {} // Pass an empty object as the data payload
      );
    } catch (e) {}
    try {
      // Logout od MUSE
      await axios.get(
        "https://any:any@ehealth.telekom.mk/muse/musescripts/museweb.dll?DisplayHomePage"
      );
    } catch (e) {}
    localStorage.removeItem("jwt");
    localStorage.removeItem("setNotImportant");
    localStorage.removeItem("setNotImportant2");
    navigate("/home/login");
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const handleBrowseData = () => {
    setIsLoading(true);
    // Odnesi go na VSCAN files
    window.location.href = "https://ehealth.telekom.mk/files/";
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleLoginToMuse = async () => {
    setIsLoading1(true);
    const storedEncodedData = localStorage.getItem("setNotImportant");
    const storedEncodedData2 = localStorage.getItem("setNotImportant2");
    try {
      // Logiraj go na MUSE
      await axios.get(
        `https://${atob(storedEncodedData)}:${atob(
          storedEncodedData2
        )}@ehealth.telekom.mk/muse/musescripts/museweb.dll?InitializeDefaultPage?Frames=1`
      );

      // Odnesi go na MUSE files
      window.location.href = `https://ehealth.telekom.mk/muse/musescripts/museweb.dll?InitializeDefaultPage?Frames=1`;
    } catch (error) {
    } finally {
      setTimeout(() => {
        setIsLoading1(false);
      }, 1000);
    }
  };

  if (accessLevel === "null") {
    return null;
  } else {
    return (
      <div className={styles.container}>
        <Logo alt="" className={styles.logo} />
        <div className={styles.buttonWrapper}>
          {(accessLevel === "VScanUsers" ||
            accessLevel === "FullAccessUsers") && (
            <Link to="/home/diskquota" className={styles.a1}>
              Disk Space
            </Link>
          )}
          {(accessLevel === "VScanUsers" ||
            accessLevel === "FullAccessUsers") && (
            <button
              onClick={handleBrowseData}
              disabled={isLoading}
              className={
                isLoading
                  ? `${styles.unavailableButton93}`
                  : `${styles.browseData1}`
              }
            >
              ECHO Images
            </button>
          )}
          <Link to="/home/changePassword" className={styles.b1}>
            Change Password
          </Link>
          {(accessLevel === "EhealthUsers" ||
            accessLevel === "FullAccessUsers") && (
            <button
              onClick={handleLoginToMuse}
              disabled={isLoading1}
              className={
                isLoading1
                  ? `${styles.unavailableButton93}`
                  : `${styles.browseData1}`
              }
            >
              ECG Images
            </button>
          )}
          <button onClick={handleLogout} className={styles.logout}>
            X
          </button>
        </div>
      </div>
    );
  }
};

export default HomeScreen;
