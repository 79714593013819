import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";
import axios from "axios";
// import TwoWayAuth from "../components/TwoWayAuth";

import styles from "../assets/styles/loginScreen.module.css";
import api from "../middleware/api";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isTwoWay, setIsTwoWay] = useState(null);
  const [pustiGo, setPustiGo] = useState(false);

  const [isLoading, setIsLoading] = useState(null);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Izbrisi prazno mesto od passwordot
    if (name === "username") {
      const valueWithoutSpaces = value.replace(/\s/g, "");
      setEmail(valueWithoutSpaces);
      return;
    }
    if (name === "password") setPassword(value);
  };

  // const [thisIsNotTheName, setThisIsNotTheName] = useState("");
  // const [thisIsNotThePassword, setThisIsNotThePassword] = useState("");

  ////// Ako Sakas 2FA
  // useEffect(() => {
  //   const attemptLogin = async () => {
  //     if (pustiGo) {
  //       try {
  //         const response1 = await axios.post(
  //           "https://ehealth.telekom.mk/api/login",
  //           {
  //             username: thisIsNotTheName,
  //             password: thisIsNotThePassword,
  //             recaptcha: "",
  //           }
  //         );

  //         const renewResponse = await axios.post(
  //           "https://ehealth.telekom.mk/api/renew",
  //           {},
  //           {
  //             headers: {
  //               "X-Auth": response1.data,
  //             },
  //           }
  //         );

  //         localStorage.setItem("jwt", response1.data);
  //         navigate("/home/");
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     }
  //   };

  //   if (pustiGo) {
  //     attemptLogin();
  //   }
  // }, [pustiGo, navigate, thisIsNotTheName, thisIsNotThePassword]);
  /////////////////////////////////////

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.includes("@")) {
      setError("Please enter a username\nEmail addresses are not accepted");
      return;
    }

    if (!/^[a-zA-Z0-9.]+$/.test(email) || !/^[a-zA-Z0-9]+$/.test(password)) {
      setError("Invalid Credentials");
      return;
    }

    try {
      setIsLoading(true);

      // Logiraj go na MySQL
      const response = await axios.post(`/backend/api/DoctorAuth/login`, {
        email: email,
        password: password,
      });
      if (response.data.message == "Success") {
        setIsTwoWay(true);

        const { thisIsNotTheName, thisIsNotThePassword } = response.data;

        // Ti trebaat za MUSE
        localStorage.setItem("setNotImportant", btoa(thisIsNotTheName));
        localStorage.setItem("setNotImportant2", btoa(thisIsNotThePassword));

        // setThisIsNotTheName(response.data.thisIsNotTheName);
        // setThisIsNotThePassword(response.data.thisIsNotThePassword);

        const responseUser = await api.get(`/api/DoctorAuth/user`, {
          withCredentials: true,
        });

        const accountStatus = responseUser.data.accountStatus.accountStatus;

        // Vidi dali e aktiven statusot vo MySQL
        if (accountStatus === "Activated") {
          // Zemi jwt token od Filebrowserot
          const response1 = await axios.post(
            "https://ehealth.telekom.mk/api/login",
            {
              username: thisIsNotTheName,
              password: thisIsNotThePassword,
              recaptcha: "",
            }
          );
          // Zemi auth token od Filebrowserot
          await axios.post(
            "https://ehealth.telekom.mk/api/renew",
            {},
            {
              headers: {
                "X-Auth": response1.data,
              },
            }
          );

          localStorage.setItem("jwt", response1.data);
          navigate("/home/");
        } else {
          navigate("/home/activateAccount");
        }
      }
    } catch (error) {
      setError(error.response.data.message || error.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Logo alt="" className={styles.logo} />
      {/* Ako sakas 2FA */}
      {/* {isTwoWay && <TwoWayAuth setPustiGo={setPustiGo} />} */}
      <div className={styles.anotherWrapper}>
        <form className={styles.formA} onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <input
              name="username"
              type="text"
              autoComplete="off"
              className={styles.input}
              value={email}
              onChange={handleInputChange}
              required
            />
            <label className={styles.userLabel}>Username</label>
          </div>
          <div className={styles.inputGroup}>
            <input
              name="password"
              type="password"
              autoComplete="off"
              className={styles.input}
              value={password}
              onChange={handleInputChange}
              required
            />
            <label className={styles.userLabel}>Password</label>
          </div>
          {isLoading ? (
            <div className={styles.wrapper1}>
              <span className={styles.loader}></span>
            </div>
          ) : (
            error && <div className={styles.error}>{error}</div>
          )}
          <div>
            <button
              disabled={isLoading}
              className={
                isLoading ? `${styles.unavailableButton55}` : `${styles.lol55}`
              }
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
