import { Outlet } from "react-router-dom";

import styles from "./assets/styles/app.module.css";

function App() {
  return (
    // Vo outlet se renderira komponentata koja sto e setirana na taa pateka vo index.js
    <div className={styles.app}>
      <Outlet />
    </div>
  );
}

export default App;
