import React, { useState, useEffect, useContext } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import axios from "axios";
import MyContext from "../context/MyContext";
import api from "../middleware/api";

function AdminRoute() {
  const [isAuthenticated, setIsAuthenticated] = useState("null");
  const location = useLocation();

  const { accessLevel, setAccessLevel } = useContext(MyContext);

  useEffect(() => {
    // zemi JWT od filebrowser, ako nema nemas pristap
    var jwtToken = localStorage.getItem("jwt");
    if (
      !jwtToken ||
      jwtToken == null ||
      jwtToken.length < 5 ||
      jwtToken == "null"
    ) {
      setIsAuthenticated("false");
    }
    const checkToken = async () => {
      try {
        // proveri JWT token od MySQL
        const whoIsThis = await axios.get(`/backend/api/DoctorAuth/user`, {
          withCredentials: true,
        });

        setIsAuthenticated("true");

        // provery dali e aktiviran vo MySQL
        if (whoIsThis.data.accountStatus.accountStatus != "Activated") {
          setIsAuthenticated("notActivated");
        }

        // Vidi vo koj OU e
        const whoIsThisAccess = await api.get(`/api/AD/getDNWthJWT`, {
          withCredentials: true,
        });
        if (whoIsThisAccess.data.indexOf("VScanUsers") !== -1) {
          setAccessLevel("VScanUsers");
        } else if (whoIsThisAccess.data.indexOf("EhealthUsers") !== -1) {
          setAccessLevel("EhealthUsers");
        } else if (whoIsThisAccess.data.indexOf("FullAccessUsers") !== -1) {
          setAccessLevel("FullAccessUsers");
        } else {
          setAccessLevel("null");
        }
      } catch (error) {
        setIsAuthenticated("false");
      }
    };

    checkToken();
  }, [location, isAuthenticated]);

  // Render nothing until authentication check is complete
  if (isAuthenticated === "null") {
    return null;
  }

  // Render based on Ako e aktiven
  if (isAuthenticated === "notActivated") {
    return <Navigate to="/home/activateAccount" />;
  }

  // Render based on isAuthenticated state
  if (isAuthenticated !== "true") {
    return <Navigate to="/home/login" />;
  }

  return <Outlet />;
}

export default AdminRoute;
