import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { MyProvider } from "./context/MyContext";
import reportWebVitals from "./reportWebVitals";

import App from "./App";
import LoginScreen from "./screens/LoginScreen";
import AdminRoute from "./components/AdminRoute";
import HomeScreen from "./screens/HomeScreen";
import ChangePasswordScreen from "./screens/ChangePasswordScreen";
import ActivateAccountScreen from "./screens/ActivateAccountScreen";
import DiskQuota from "./screens/DiskQuota";

import "./index.css";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/home" element={<App />}>
      <Route path="/home/login" element={<LoginScreen />} />
      <Route path="/home/activateAccount" element={<ActivateAccountScreen />} />
      {/* nemoze da stigne do tuka zaso adminroute se povikuva treba  */}
      <Route path="/home" element={<AdminRoute />}>
        <Route index={true} path="" element={<HomeScreen />} />
        <Route path="/home/changePassword" element={<ChangePasswordScreen />} />
        <Route path="/home/diskquota" element={<DiskQuota />} />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));

// MyProvider ti e za contextot za globalni promenlivi da se dostapni vo sekoja komponenta
// RouterProvider ti e za ruterot
root.render(
  <React.StrictMode>
    <MyProvider>
      <RouterProvider router={router} />
    </MyProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
